import PropTypes from "prop-types"
import React, { createContext, useReducer } from "react"

import api from "apis"
import { history } from "app-store-v2"

import * as actions from "./actions.js"

const initialState = {
  article: {},
  categoryResults: {},
  collections: {},
  searchResults: {},
}

export const HelpdeskContext = createContext(initialState)

const helpdeskReducer = (state = initialState, action) => {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case "SET_SEARCH_RESULTS":
      newState.searchResults = action.data
      return newState
    case "CLEAR_SEARCH_RESULTS":
      newState.searchResults = {}
      return newState
    case "SET_CATEGORY_RESULTS":
      newState.categoryResults = action.data
      return newState
    case "CLEAR_CATEGORY_RESULTS":
      newState.categoryResults = {}
      return newState
    case "SET_ARTICLE":
      newState.article = action.data
      return newState
    case "SET_COLLECTIONS":
      newState.collections = action.data
      return newState
    default:
      return state
  }
}

const HelpDeskProvider = ({ children }) => {
  const [state, dispatch] = useReducer(helpdeskReducer, initialState)
  HelpDeskProvider.propTypes = {
    children: PropTypes.object,
  }
  return (
    <HelpdeskContext.Provider value={{ dispatch, state }}>
      {children}
    </HelpdeskContext.Provider>
  )
}

export const fetchHelpdeskSearchResults = async (query, dispatch, state) => {
  if (!query && history.location.pathname == "/helpdesk/search") {
    history.replace("/helpdesk")
    return null
  }

  if (!query) {
    return null
  }

  let initialPage =
    history.location.pathname == "/helpdesk/search" && !state.searchResults.page

  if (initialPage) {
    history.replace(`/helpdesk/search?query=${query}`)
  } else {
    history.push(`/helpdesk/search?query=${query}`)
  }

  // Clear search results to invoke a loading indication
  actions.clearSearchResults()

  const result = await api.get(`/api/v4/search?query=${query}`)

  if (!result.success) {
    dispatch(actions.setSearchResults(result.message))
    return null
  }

  dispatch(actions.setSearchResults(result.payload.articles))
}

export const fetchArticleResults = async (articleNumber, dispatch) => {
  const result = await api.get(`/api/v4/article/${articleNumber}`)
  if (
    result?.data &&
    result.data?.related_articles &&
    !result.data.related_articles.length
  ) {
    const response = await api.get(
      `/api/v4/category_articles?category_id=${result.data.article.categories}`
    )
    result.data.related_articles = (
      response?.payload?.articles?.items || []
    ).filter((item) => item.number != articleNumber)
  }
  if (result?.data) {
    dispatch(actions.setArticle(result.data))
    fetchCollection(result.data.article.collectionId, dispatch)
  } else {
    dispatch(actions.setArticle(result))
  }
}

export const fetchArticlesByCategory = async (categoryId, dispatch) => {
  const result = await api.get(
    `/api/v4/category_articles?category_id=${categoryId}`
  )

  if (result.success) {
    dispatch(actions.setCategoryResults(result.payload.articles))
  } else {
    dispatch(actions.setCategoryResults(result.message))
  }
}

export const fetchCollection = async (collectionId, dispatch, categoryId) => {
  let fetchUrl = "/api/v4/article/collection"
  if (collectionId && categoryId) {
    fetchUrl = `/api/v4/article/collection/${collectionId}/${categoryId}`
  } else if (collectionId && !categoryId) {
    fetchUrl = `/api/v4/article/collection/${collectionId}`
  }

  const result = await api.get(fetchUrl)
  if (result?.data) {
    dispatch(actions.setCollections(result.data))
  } else {
    dispatch(actions.setCollections(result))
  }
}

export default HelpDeskProvider
export * from "./actions.js"
