import { Box } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useRef } from "react"

import { useIntersection } from "helpers/use-intersection"

// eslint-disable-next-line react/prop-types
const IntersectionWrapper = ({ children, ...props }) => {
  const ref = useRef()
  const inViewport = useIntersection(ref, "50px")
  return (
    <Box minHeight="50px" ref={ref} {...props}>
      {inViewport && children}
    </Box>
  )
}

IntersectionWrapper.propTypes = {
  children: PropTypes.node,
}

export default IntersectionWrapper
