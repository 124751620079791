import { Box } from "@jewlr/storybook/core"
import loadable from "@loadable/component"
import PropTypes from "prop-types"
import React from "react"
import { withRouter } from "react-router-dom"

import IntersectionWrapper from "components/common/intersection-wrapper"
import { retry } from "helpers/application"

const FooterBottom = loadable(() => retry(() => import("./footer-bottom")))
const FooterContent = loadable(() => retry(() => import("./footer-content")))
const FooterSubscribe = loadable(() =>
  retry(() => import("./footer-subscribe"))
)

const FooterMain = ({ location }) => {
  if (
    location.pathname.includes("/checkout") ||
    location.pathname.includes("/payments")
  ) {
    return ""
  }

  return (
    <IntersectionWrapper id="footer-wrapper">
      <FooterSubscribe />
      <Box
        as="footer"
        bg="blackPrint"
        pb={{ _: "60px", desktop: "36px" }}
        pt={{ _: "30px", desktop: "100px" }}
      >
        <FooterContent />
        <FooterBottom />
      </Box>
    </IntersectionWrapper>
  )
}

FooterMain.propTypes = {
  location: PropTypes.object,
}

export default withRouter(FooterMain)
