import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"

import { setCookieLoginStatus } from "app-store-v2/app/actions"

export default function fetchUser(WrappedComponent) {
  const InternalComponentName = (props) => {
    React.useEffect(() => {
      props.checkIfLoggedIn()
    }, [])
    return <WrappedComponent {...props} />
  }

  InternalComponentName.propTypes = {
    checkIfLoggedIn: PropTypes.func,
    userCookie: PropTypes.bool,
  }

  const mapStateToProps = (state) => {
    return {
      userCookie:
        // Boolean check to see if user data is stored.
        // user_obj cookie is depreciated and is now replaced with
        // local storage.
        typeof window !== "undefined"
          ? Object.keys(state.user.jewlrorders).length > 0
          : null,
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      checkIfLoggedIn: () => {
        if (!window.loginStatus) {
          dispatch(setCookieLoginStatus())
        }
      },
    }
  }

  const ComponentName = connect(
    mapStateToProps,
    mapDispatchToProps
  )(InternalComponentName)

  return ComponentName
}
