export const clearSearchResults = () => {
  return { type: "CLEAR_SEARCH_RESULTS" }
}

export const setSearchResults = (data) => {
  return { data, type: "SET_SEARCH_RESULTS" }
}

export const clearCategoryResults = () => {
  return { type: "CLEAR_CATEGORY_RESULTS" }
}

export const setArticle = (data) => {
  return { data, type: "SET_ARTICLE" }
}

export const setCollections = (data) => {
  return { data, type: "SET_COLLECTIONS" }
}

export const setCategoryResults = (data) => {
  return { data, type: "SET_CATEGORY_RESULTS" }
}
